
import { useState, useCallback, useEffect } from "react";

const useGeolocation = () => {
  const [location, setLocation] = useState({
    latitude: sessionStorage.getItem("latitude") || null,
    longitude: sessionStorage.getItem("longitude") || null,
  });

  const getUserLocation = useCallback(() => {
    if (!("geolocation" in navigator)) {
      console.error("Geolocation is not available on this device");
      return;
    }

    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        sessionStorage.setItem("latitude", latitude);
        sessionStorage.setItem("longitude", longitude);
        setLocation({ latitude, longitude });
      },
      (error) => {
        console.error("Unable to retrieve your location", error.message);
      },
      { enableHighAccuracy: true, timeout: 10000, maximumAge: 0 }
    );
  }, []);

  useEffect(() => {
    if ("permissions" in navigator) {
      navigator.permissions.query({ name: "geolocation" }).then((permission) => {
        if (permission.state === "granted") {
          getUserLocation();
        }

        permission.onchange = () => {
          if (permission.state === "granted") {
            getUserLocation();
          } else if (permission.state === "denied") {
            setLocation({ latitude: null, longitude: null });
            sessionStorage.removeItem("latitude");
            sessionStorage.removeItem("longitude");
          }
        };
      });
    }
  }, [getUserLocation]);

  return { location, getUserLocation };
};

export default useGeolocation;
