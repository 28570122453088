import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Stack } from '@mui/material';
import '../../../fonts.css';
import '../Home.css';
const CustomDiv = styled.span`
  font-family: 'JohnsonText-Regular', sans-serif;
  font-weight: bold;
  paddingleft: 8px;
  paddingrightt: 8px;
`;
const CustomH2 = styled.h2`
  font-family: 'JohnsonDisplay-Regular', sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #eb1700;
  margin: 0;
  paddingleft: 8px;
  paddingrightt: 8px;
`;
const CustomLi = styled.li`
  font-family: 'JohnsonText-Regular', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
`;
function MidComponent({ isMobile }) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const { t } = useTranslation();
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    //This line adds an event listener to the resize event of the window
    window.addEventListener('resize', handleResize);
    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <Stack
      direction={{ xs: 'column', sm: 'column', md: 'row' }}
      alignItems="flex-start"
      justifyContent={'space-between'}
      sx={{ borderBottom: '2px dotted #D2DCE6' }}
    >
      {/* paddingRight: '20px' */}
      <Stack
        direction={'column'}
        sx={{
          borderRight: !isMobile && '2px dotted #D2DCE6',
          padding: {
            xs: '0 8px',
            md: '0 23px 0 8px',
          },
          flexBasis: {
            md: '49%',
          },
        }}
      >
        <CustomDiv>{t('main.instructions')}</CustomDiv>
        <CustomH2>{t('main.DMScan.title')}</CustomH2>
        <Stack direction={{ xs: 'column', sm: 'column', md: 'row' }}>
          {isMobile ? (
            <img
              src="images/mob-scan.png"
              width={`${windowWidth - 16}px`}
              height={'144px'}
              style={{
                backgroundColor: 'white',
                marginTop: '8px',
                objectFit: 'contain',
              }}
              aria-label='GTIN Scan'
            />
          ) : (
            <img
              src="images/ScanImg.png"
              width={'184px'}
              height={'168px'}
              style={{ backgroundColor: '#D2DCE6', margin: '16px 0' }}
              aria-label='GTIN Scan'
            />
          )}
          <ol style={{ marginLeft: isMobile && '-8px' }}>
            <CustomLi>{t('main.DMScan.content.line1')}</CustomLi>
            <CustomLi>{t('main.DMScan.content.line2')}</CustomLi>
            <CustomLi>{t('main.DMScan.content.line3')}</CustomLi>
            <CustomLi>{t('main.GTINSearch.content.line4')}</CustomLi>
          </ol>
        </Stack>
      </Stack>
      <Stack
        direction={'column'}
        sx={{
          marginLeft: !isMobile && '15px',
          padding: '0 8px',
          flexBasis: {
            md: '49%',
          },
        }}
      >
        {!isMobile && <CustomDiv>{t('main.instructions')}</CustomDiv>}
        <CustomH2 style={isMobile ? { marginTop: '16px' } : {}}>{t('main.GTINSearch.title')}</CustomH2>
        <Stack direction={{ xs: 'column', sm: 'column', md: 'row' }}>
          {isMobile ? (
            <img
              src="images/mob-GTIN.png"
              width={`${windowWidth - 16}px`}
              height={'144px'}
              style={{
                backgroundColor: 'white',
                marginTop: '8px',
                objectFit: 'contain',
              }}
              aria-label='GTIN Search'
            />
          ) : (
            <img
              src="images/GTINImg.png"
              width={'184px'}
              height={'168px'}
              style={{ backgroundColor: '#D2DCE6', margin: '16px 0' }}
              aria-label='GTIN Search'
            />
          )}
          <ol style={{ marginLeft: isMobile && '-8px' }}>
            <CustomLi>{t('main.GTINSearch.content.line1')}</CustomLi>
            <CustomLi>{t('main.GTINSearch.content.line2')}</CustomLi>
            <CustomLi>{t('main.GTINSearch.content.line3')}</CustomLi>
            <CustomLi>{t('main.GTINSearch.content.line4')}</CustomLi>
          </ol>
        </Stack>
      </Stack>
    </Stack>
  );
}
export default MidComponent;
