export const environment = {
  production: false,
  gs1url: "http://localhost:8000",
  scanditKey:
    "AQeDWyKtBGJdQp37JxIz7htFawpGKsUQ62PygBJFNfN0Q9zHqRRVzhR83yloQ66vYF8KGVdTtOLaf/KezWl9Qhp5xBapIzuGHWPvsIZeuNqlU6U5VAsbuW5r5oCGWLKZqW5PsO8fO65sULoRVDhE6NMEEzLLPLsXwQ0xujAlRj552DWMCIHwbh7cDQxVnoxgQWTfh1x3foS/fG4LxR5BqJMj4lsg+XOFSjaZuHce3Q56pJhjTqp40Eiu65K76c1+nzLhn3uJeNWXKPkD4vgNzj+1OFVWQCxG24Z9c4NCXFd0wfk78ZoQbKg/5yuzi6Q9bt6ZOh9YKtovvPJrTX2S6T8IqgJkds90H6K6tNnLOiNCb+Rg8/yNN3BjzP9WfMCkTIOFp8c4xpyyKpYeTYhZVho672iBNnlRzdkw2AliEzwfTOtj03DkuCO07r/5TU6J0ZWgdTOMskwqrbiJxlZoUALRvWUgdE92JEU4Qf15VMBY7m+pIlST7RsV3kHy3s+x2UduLzWPyh8uui0UJCQgOt1OaESvGRdc0jRNAQP5BdakleskzHu1BmNDulgTlD/Dp9O3jNi/XjSQusfzFxYvumdSpfgb9YYnZDn4KoW2ONGMCagrEdr4Tc1YXmw6P5S1LFxfK+u30xAs0GIfFXzHBJRCh/qKe7UOG79AVJVqnUvlhkwwLFIQuKURSrWGiPbB+zm7aAd022E9FAEab1inHXUK7yTJXCZowyxx/X5IlkUjABEQxon9ierALLF8jWMU05E7tzvTImd7OXA08LQ7BzJLmeQYdxAEryTAjjdLFC/E+fIFdPNdqUeH046X6Uq51vx5eLWS8cTtbKQ=",
  scanditKeyEpiTest:
    "ASNFBCSlDN6YLFjYwEVEvms7S8VERMfUB1c+OcVoorAZdYGZtgPa6cIR2YhfXht9gDs6Fk9sv0ujQwNq/2q+SlJXrsdqLdiLx1P5txMVdVJ1Qarw6kwdgQ5JeZWxbrPgAinS/l43/kT9RF1J8xIDSPI/o2kG6FVreSbDGLnr0+ohVJn+Y2IJD3c0bNQTeIhVS56jGUo05jSiepvhJ5K5zeo+q+2NBan4fHIHGiBID2nmpKoFsL61kfKqvrdAT6ZBEFO2bWM1JcDve76hy4LWTns3FIc3G9q7GgPgTuDpDp8GRwQtajBd5pCZt8DE97NnEnQqeNsN61ehUng8kgXkNPThHRPs1duh0wYxDTYC/131PIBCWPgxW+MzAej9BCRhiIq/ZSTp/ZKWpb4tVGG6eAJjNbUmH/Y3N+RizEh36Ov/XsfyaOfD4Utmt0d7PhCOLZFz6ushLOwV6exqDqIWuKdWVc6VHiMMWSyci/3l1GpJADlceWcP5VmqQBfbJF+FLvWhB0bshh1BGkHjsbi0Ea50DZkLbYEcZo1KR5vHE/JpgvA406M3oVBRLIAo7EJaW53oH1kGTrFmG8vp/G4H5tFr5aJfyiuA1jRTbEEZ7Qq4HrWimip4JdGhQcyao79j8P41UNuwpIGwISHF28x9kROD6EGiNQK78DCOB0KoGTQXqEuYg4/L2ogjwXHYPJWU5K0Cbq5oTUf9H5SlISF4O9Xvc8sfvpfmaRl37UCtseHqeoDsyBmCFYKBqmh0ABBaMdmTNxt5X7qf9M6gKEjjjh209Eg8kPeIJ6FpupVFOdWDk2ohDXMtFXj8C1XN/0E=",
  scanditKeyElabel:
    "AfvD3NOBRzaIQ7ijGAacMPcsV0INJU4XD3MGfGVbgpTvZVdmgwnvhthQM5iHQrbuWHgzRAlP3eH/bzmBKlqOAdBsntaHfdNxal7U7Ldud+TVHjoBalugjzlCUM40JdoqdnO0Aot/WczZcyc1FD40UcUHBaBNFt518ysDb8lvqqzOi1jqCsIgIybY5mHOGy6DZw8gvij2qn4uyEjtOYLo2iiWmabiCa18AHan95yGRCP2T7PP4huas2XYE5PW/ZgAVcylixMAqWS4xbXWWhcnrk/OYqgBzD4zKVwZaUoXPtyca5o5/3sfYGajwL8OIApdrj8ZXE0UnuvGYMVEaLTAduz5E8su4JoQ4AuypgdQWvci2+oeEudzGL9chHwKWPH36A2Gamoey/o4bhe4UYmSgzqp5u88fZNQS2zvN1jold9z0WPCc8LkwFG0W6k+afK5KcoCE0XAgMhHmZlNUJnIRG/X3CciR25q4OXmrn1PrkQlkmsax0lam+U5AfB4FhSuaEOE/ATVGFFm74gLF/xVgk/4cToSFMCF0qFagGzdhg8+k1dJ4Tg2VeUG4hhPM52XQpHudgs9mlLYzEeq4RcuRNFhXsDF+c9oDLV+twhOvDIBQF4qeS00so7pQO/XG34YeylXHImndhixnnN54OTycLgFmEeQ3+5yhFDiV59aOz8GtjrQ8LE6IGuxPv7ljRVaewjOEkANkVvmlpMSBIHOvvY9bDjMHcLfGFl00IyglP6YeIEWLXDKX5Sk2xvdOlSTix3ojoxhctPnEt2sLkcJsf3b6EBxfWxJedctFN9Ql7DJowABUPFsBBycMJIXW3gavaZQaKPr+6+itHsT",
  scanditKeyEpi:
    "AWqz9rSBIA1+CX7jYgzG2Hs+u0OvL2zJrXMEWSFVpiNNU/WIoSFtGPltsts6eZC3D0hZF8B0ahGqczbvxmSn0o15ohK3UR9gdSuzP/1+EJsNSoRFnilS3JJmMqh7Qvu9hiP2Iy40DbXgCUByrf4JbI3UGa1R39BYsF8wWe8KV4UhUEalfIJGDDkVvCBa9G/ozYXdU17TURpRczl1KFvdYQ7OhCafPLrYY4SSe+1gqF3O8jI8+fH3jXXS0PTeRcmks488v3Yv5CS67GHDZyZZjf3pl8eKC/45x1swwpcc6HT99zZPUuQWscEDoV/WUN9uAaJqVtBGHxgKjVk9PX/H1gqeMvT4Zp37ImTiZ0jMHGPh2vAtw+ii38nfEZDpbeGdHVapzoVwFZ75Ck9CPBPzEfaWdQQwUDJw+5mSU6FMxrDFBspD+zIZGU26DaaLDaKUyloKfgHaaVTBsaXuBt/Bbxmxj/l4PR0KSZVDl9Ob4Jt61FNYIIUNIMYfT9w2Os1SKIFkAUxG5SVs5Mz4j6y8O8xDVQ6YtzhOBh/e0IHctew7bJkNLbM0jJuokjxPLrrkgGHmK9Kqz13hVZaalD7eL/Hp768NPvv6EavuI0agGsb1sYvaKCOBZ43cOlnvRPwKMgrAJWaDDx+B2d93A/8mflCHYIItqxYLa9UzOyWXlIOPD3pnDL+ALNNtzaIBNAImhsU8UwANDR6DMw6bNY82k7Q6od6cT1+69mU/MKieOyblSQIRJmLiTO7I7L6M8yE7APz+skZEP83YPg/G5ixkz06/srAd/GByrdXYnp78h8qa/0x85olg6khA3g4=",
  version: "5.8.0",
};
