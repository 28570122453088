import React, { useState, useEffect } from 'react';
import { useSearchParams, useParams, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import styled from '@emotion/styled';
import axios from 'axios';
import Title from '../Title';
import '../Home.css';
import GS1DigitalLinkToolkit from '../../../assets/js/GS1DigitalLinkToolkit';
import { environment } from '../../../environments/environment';

const StyledTypography = styled(Typography)(() => ({
    fontFamily: 'JohnsonText-Regular, sans-serif',
    fontWeight: '700',
    fontSize: '16px',
}));
const CustomH2 = styled.h2`
  font-family: 'JohnsonDisplay-Regular', sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  color: #eb1700;
`;
const CustomText = styled(Typography)(({ marginLeft }) => ({
    fontFamily: 'JohnsonText-Regular, sans-serif',
    fontWeight: '400',
    fontSize: '16px',
    color: 'black',
    marginLeft: typeof marginLeft === 'string' ? parseInt(marginLeft, 10) + 'px' : marginLeft || 0,
}));

const Expired = styled.div`
  padding: 7px 10px;
  background-color: #d61401;
  color: #fff;
  position: relative;
  margin: -5px 0 0 15px !important;
  font-family: 'JohnsonText-Regular', sans-serif;
  font-size: 13px;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: -18px;
    transform: translateY(-50%);
    border-width: 10px;
    border-style: solid;
    border-color: transparent #d61401 transparent transparent;
  }
`;

function SearchDetails() {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const { "*": dynamicPath } = useParams(); // Extract wildcard path
    const [searchParams] = useSearchParams(); // Extract query parameters
    const [isExpired, setIsExpired] = useState(false);
    const [collection, setCollection] = useState({});
    const [linkSet, setLinkSet] = useState([]);
    const [gs1Array, setGs1Array] = useState([]);
    const [languages, setLanguages] = useState([]);
    const [countries, setCountries] = useState([]);
    const userLanguage = navigator.language.split('-')[0];
    const lastLanguage = localStorage.getItem('language') || userLanguage;
    const [selectedLanguage, setSelectedLanguage] = useState(lastLanguage);
    const { t } = useTranslation();

    const gs1dlt = new GS1DigitalLinkToolkit();

    const padding = '16px 8px';

    const getProductList = async (gs1ElementStrings, params) => {
        let language_list = [];
        try {
            const language_response = await axios.get('data/language.json');
            language_list = language_response.data;
            setLanguages(language_response.data);

            const country_response = await axios.get('data/country.json');
            setCountries(country_response.data);
        } catch (error) {
            console.log(error.message);
        }

        let gs1DigitalLinkURI;
        gs1ElementStrings = decodeURIComponent(gs1ElementStrings);
        try {
            gs1DigitalLinkURI = gs1dlt.gs1ElementStringsToGS1DigitalLink(
                gs1ElementStrings,
                false,
                // environment.gs1url,
                window.location.origin,
                //'https://qa.elabel.jnj.com/'
            );
        } catch (e) {
            alert(e.message);
            return;
        }

        // get language and country from local storage
        let language = (selectedLanguage || 'EN').toUpperCase();

        let country = localStorage.getItem('country') || 'US';
        // check if the gs1DigitalLinkURI contains query parameters
        let separator = gs1DigitalLinkURI.indexOf('?') > -1 ? '&' : '?';
        // append the query parameters
        gs1DigitalLinkURI += separator + 'linkType=all' + '&context=' + country;
        if (params) {
            for (const key in params) {
                if (params.hasOwnProperty(key)) {
                    const value = params[key];
                    gs1DigitalLinkURI += `&${key}=${value}`;
                }
            }
        }
        // Fetch headers
        const headers = new Headers();
        headers.append('Accept', 'application/linkset+json');
        /** Collection */
        let collection = {
            anchor: '',
            itemDescription: '',
        };
        /** If values are array merge to masterCollection */
        let masterCollection = [];
        fetch(gs1DigitalLinkURI, { headers })
            .then((response) => {
                if (response.status !== 200) {
                    throw new Error('Not Found');
                }
                return response.json();
            })
            .then((data) => {
                collection = data.linkset[0];
                let values = Object.values(collection);
                values.forEach((value) => {
                    if (Array.isArray(value)) {
                        masterCollection = masterCollection.concat(value);
                    }
                });
                let list = masterCollection.map((item) => {
                    let lang;
                    if (item?.hreflang) {
                        lang = item.hreflang[0];
                        lang = lang?.substring(0, 2);
                    }
                    return {
                        url: item.href,
                        type: 'fa-cube',
                        content: item.type,
                        name: item.title || 'Product Information',
                        lang: (lang || '').toUpperCase(),
                        context: (item.context || [])[0]?.toUpperCase(),
                    };
                });
                // remove data with empty url or lang
                list = list
                    .filter((item) => {
                        return item.url && item.lang;
                    })
                    .filter(
                        (value, index, self) =>
                            index ===
                            self.findIndex(
                                (t) =>
                                    t.url === value.url && t.name === value.name && t.lang === value.lang && t.content === value.content,
                            ),
                    );
                
                // group by content 1. PDF, 2. WebPage, 3. Image, 4. Audio, 5. Video
                // under each content type, selected language should be first
                // rest should be sorted by language
                let grouped = list.reduce((r, a) => {
                    r[a.content] = r[a.content] || [];
                    r[a.content].push(a);
                    return r;
                }, Object.create(null));

                let sorted = [];
                let contentOrder = ['application/pdf', 'text/html', 'image/jpeg', 'audio/mp3', 'video/mp4'];
                for (let i = 0; i < contentOrder.length; i++) {
                    let content = contentOrder[i];
                    if (grouped[content]) {
                        let group = grouped[content];
                        group.sort((a, b) => {
                            const a_lang = (language_list.find((lang) => lang.iso_639_1_code.toLowerCase() === a.lang.toLowerCase())?.name || a.lang).toLowerCase();
                            const b_lang = (language_list.find((lang) => lang.iso_639_1_code.toLowerCase() === b.lang.toLowerCase())?.name || b.lang).toLowerCase();
                            if (a_lang < b_lang) {
                                return -1;
                            }
                            if (a_lang > b_lang) {
                                return 1;
                            }
                            return 0;
                        });

                        group.sort((a, b) => {
                            if (a.lang.toLowerCase() === language.toLowerCase()) {
                                return -1;
                            }
                            if (b.lang.toLowerCase() === language.toLowerCase()) {
                                return 1;
                            }
                            return 0;
                        });
                        sorted = sorted.concat(group);
                    }
                }

                // remove duplicates
                list = sorted.filter((value, index, self) => {
                    return (
                        index ===
                        self.findIndex(
                            (t) =>
                                t.url === value.url && t.name === value.name && t.lang === value.lang && t.content === value.content,
                        )
                    );
                });

                if (localStorage.getItem('isHcp') === 'true') {
                    collection.anchor += '?linkType=gs1:smpc';
                }
                let gs1Ar = gs1dlt.extractFromGS1elementStrings(gs1ElementStrings);
                if (gs1Ar['17']) {
                    let year = parseInt('20' + gs1Ar['17'].substring(0, 2));
                    let month = parseInt(gs1Ar['17'].substring(2, 4)) - 1;
                    let day = parseInt(gs1Ar['17'].substring(4));

                    // if day is zero, then get the last day of the month
                    if (day == 0) {
                        day = new Date(year, month + 1, 0).getDate();
                    }

                    /* convert yymmdd to timestamp */
                    var expiry = new Date(year, month, day);
                    expiry.setHours(23, 59, 59, 999);

                    // get locale
                    // let locale = navigator.language || 'en-US';
                    // gs1Ar['17'] = expiry.toLocaleDateString(locale, { day: '2-digit', month: 'short', year: 'numeric' });
                    // gs1Ar['17'] = gs1Ar['17'].replace(/ /g, '-');

                    // Translate month to French or Turkish based on the selected language
                    let shortMonth;
                    try {
                        shortMonth = expiry.toLocaleString(selectedLanguage, { month: 'short' });
                    } catch (e) {
                        shortMonth = expiry.toLocaleString('en-US', { month: 'short' });
                    }

                    // Format date as "dd mon yyyy"
                    gs1Ar['17'] = `${day} ${shortMonth} ${year}`;

                    /* check how many more to days to expire */
                    let daysToExpire = Math.floor((expiry.getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24));
                    if (daysToExpire <= 0) {
                        setIsExpired(true);
                    }
                }

                setGs1Array(gs1Ar);
                setCollection(collection);
                setLinkSet(list);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const getAllQueryParamsFromURL = () => {
        const urlParams = new URLSearchParams(window.location.search);
        const queryParams = Object.fromEntries(urlParams.entries());
        return queryParams;
    };

    const getLanguageFromList = (code) => {
        const language = languages.find((lang) => lang.iso_639_1_code === code.toLowerCase());
        return language?.name || code || '-';
    };

    const getCountryFromList = (code) => {
        const country = countries.find((contry) => contry.iso_3166_1_alpha_2_code === code?.toUpperCase());
        if (country) {
            // Return the equivalent language name based on the selected language
            return country[`name_${selectedLanguage}`] || country.name_en;
        } else {
            return code || '-';
        }
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        const params = getAllQueryParamsFromURL();
        console.log({
            dynamicPath, searchParams: searchParams.toString(), params
        });

        const gs1ElementPath = dynamicPath + (searchParams.toString() ? '?' + searchParams.toString() : '');
        const gs1ElementStrings = gs1dlt.gs1digitalLinkToGS1elementStrings(window.location.origin + "/" +  gs1ElementPath);
        
        getProductList(gs1ElementStrings, params);

        //This line adds an event listener to the resize event of the window
        window.addEventListener('resize', handleResize);
        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [dynamicPath, searchParams]);

    return (
        <>
            <div className="search-container">
                <Stack
                    direction={'row'}
                    // spacing={{ xs: 1, sm: 2, md: 4 }}
                    alignItems="center"
                    justifyContent={'flex-start'}
                    sx={{ gap: '8px' }}
                >
                    {!isMobile && (
                        <Link to="/" aria-label='Return Home'>
                            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" aria-label='Return Home Arrow'>
                                <rect width="40" height="40" rx="8" fill="#F1EFED" />
                                <path
                                    d="M13.3924 19.2613H27.3044C27.4889 19.2613 27.6658 19.3346 27.7963 19.465C27.9267 19.5955 28 19.7724 28 19.9569C28 20.1414 27.9267 20.3183 27.7963 20.4488C27.6658 20.5792 27.4889 20.6525 27.3044 20.6525H13.3924C13.208 20.6525 13.031 20.5792 12.9006 20.4488C12.7701 20.3183 12.6968 20.1414 12.6968 19.9569C12.6968 19.7724 12.7701 19.5955 12.9006 19.465C13.031 19.3346 13.208 19.2613 13.3924 19.2613Z"
                                    fill="#EB1700"
                                />
                                <path
                                    d="M13.6804 19.9569L19.4497 25.7248C19.5803 25.8554 19.6537 26.0325 19.6537 26.2173C19.6537 26.402 19.5803 26.5791 19.4497 26.7097C19.3191 26.8404 19.142 26.9137 18.9572 26.9137C18.7725 26.9137 18.5954 26.8404 18.4647 26.7097L12.2044 20.4494C12.1396 20.3847 12.0882 20.308 12.0531 20.2235C12.0181 20.139 12 20.0484 12 19.9569C12 19.8654 12.0181 19.7748 12.0531 19.6903C12.0882 19.6058 12.1396 19.529 12.2044 19.4644L18.4647 13.204C18.5954 13.0734 18.7725 13 18.9572 13C19.142 13 19.3191 13.0734 19.4497 13.204C19.5803 13.3346 19.6537 13.5118 19.6537 13.6965C19.6537 13.8812 19.5803 14.0583 19.4497 14.189L13.6804 19.9569Z"
                                    fill="#EB1700"
                                />
                            </svg>
                        </Link>
                    )}
                    <Title
                        title={t('GTINDetails.title', { itemDesciption: collection.itemDescription })}
                        isMobile={isMobile}
                        titlePaddingStyle={padding}
                    />
                </Stack>
                {/* Details rendering conditionally based on screen size for smaller screens*/}
                {isMobile ? (
                    <Stack
                        direction={'column'}
                        spacing={{ xs: 1, sm: 1, md: 1 }}
                        justifyContent={'flex-start'}
                        sx={{ padding: '16px 8px 8px 8px' }}
                    >
                        <Stack direction={'row'} spacing={{ xs: 1, sm: 2, md: 4 }}>
                            <Stack
                                direction={'column'}
                                spacing={{ xs: 1, sm: 1, md: 1 }}
                                justifyContent={'flex-start'}
                                sx={{ borderBottom: '2px dotted #D2DCE6', width: '50%' }}
                            >
                                <StyledTypography>{t('GTINDetails.gtinNum.title')}</StyledTypography>
                                <CustomText>{t('GTINDetails.gtinNum.gtinVal', { gtin: gs1Array['01'] })}</CustomText>
                            </Stack>
                            <Stack
                                direction={'column'}
                                spacing={{ xs: 1, sm: 1, md: 1 }}
                                justifyContent={'flex-start'}
                                sx={{ borderBottom: '2px dotted #D2DCE6', width: '50%' }}
                            >
                                <StyledTypography>{t('GTINDetails.expiryDate.title')}</StyledTypography>
                                <Stack style={{ position: 'relative' }} direction={'row'} spacing={{ xs: 1, sm: 1, md: 1 }}>
                                    <CustomText>{t('GTINDetails.expiryDate.expiryVal', { expiry: gs1Array['17'] || '-' })}</CustomText>
                                    {isExpired && <Expired>{t('GTINDetails.expiryDate.expiredImg')}</Expired>}
                                </Stack>
                            </Stack>
                        </Stack>
                        <Stack direction={'row'} spacing={{ xs: 1, sm: 2, md: 4 }}>
                            <Stack
                                direction={'column'}
                                spacing={{ xs: 1, sm: 1, md: 1 }}
                                justifyContent={'flex-start'}
                                sx={{ borderBottom: '2px dotted #D2DCE6', width: '50%' }}
                            >
                                <StyledTypography>{t('GTINDetails.batchNum.title')}</StyledTypography>
                                <CustomText>{t('GTINDetails.batchNum.batchVal', { batchNo: gs1Array['10'] || '-' })}</CustomText>
                            </Stack>
                            <Stack
                                direction={'column'}
                                spacing={{ xs: 1, sm: 1, md: 1 }}
                                justifyContent={'flex-start'}
                                sx={{ borderBottom: '2px dotted #D2DCE6', width: '50%' }}
                            >
                                <StyledTypography>{t('GTINDetails.serialNum.title')}</StyledTypography>
                                <CustomText>{t('GTINDetails.serialNum.serialVal', { serialNo: gs1Array['21'] || '-' })}</CustomText>
                            </Stack>
                        </Stack>
                    </Stack>
                ) : (
                    <Stack
                        direction={'row'}
                        spacing={{ xs: 1, sm: 2, md: 4 }}
                        alignItems="center"
                        justifyContent={'space-between'}
                        sx={{ marginBottom: '16px', gap: '16px', paddingBottom: '4px' }}
                    >
                        <Stack
                            direction={'column'}
                            spacing={{ xs: 1, sm: 1, md: 1 }}
                            justifyContent={'flex-start'}
                            sx={{
                                borderBottom: '2px dotted #D2DCE6',
                                width: '25%',
                                paddingBottom: '4px',
                                paddingLeft: '1px',
                            }}
                        >
                            <StyledTypography>{t('GTINDetails.gtinNum.title')}</StyledTypography>
                            <CustomText style={{ marginTop: '0' }}>
                                {t('GTINDetails.gtinNum.gtinVal', { gtin: gs1Array['01'] })}
                            </CustomText>
                        </Stack>
                        <Stack
                            direction={'column'}
                            spacing={{ xs: 1, sm: 1, md: 1 }}
                            justifyContent={'flex-start'}
                            sx={{
                                borderBottom: '2px dotted #D2DCE6',
                                width: '25%',
                                paddingBottom: '4px',
                            }}
                        >
                            <StyledTypography>{t('GTINDetails.expiryDate.title')}</StyledTypography>
                            <Stack
                                direction={'row'}
                                spacing={{ xs: 1, sm: 1, md: 1 }}
                                sx={{ marginTop: '0' }}
                                style={{ marginTop: '0' }}
                            >
                                <CustomText style={{ marginTop: '0' }}>
                                    {t('GTINDetails.expiryDate.expiryVal', { expiry: gs1Array['17'] || '-' })}
                                </CustomText>
                                {isExpired && <Expired>{t('GTINDetails.expiryDate.expiredImg')}</Expired>}
                            </Stack>
                        </Stack>
                        <Stack
                            direction={'column'}
                            spacing={{ xs: 1, sm: 1, md: 1 }}
                            justifyContent={'flex-start'}
                            sx={{
                                borderBottom: '2px dotted #D2DCE6',
                                width: '25%',
                                paddingBottom: '4px',
                            }}
                        >
                            <StyledTypography>{t('GTINDetails.batchNum.title')}</StyledTypography>
                            <CustomText style={{ marginTop: '0' }}>
                                {t('GTINDetails.batchNum.batchVal', { batchNo: gs1Array['10'] || '-' })}
                            </CustomText>
                        </Stack>
                        <Stack
                            direction={'column'}
                            spacing={{ xs: 1, sm: 1, md: 1 }}
                            justifyContent={'flex-start'}
                            sx={{
                                borderBottom: '2px dotted #D2DCE6',
                                width: '25%',
                                paddingBottom: '4px',
                            }}
                        >
                            <StyledTypography>{t('GTINDetails.serialNum.title')}</StyledTypography>
                            <CustomText style={{ marginTop: '0' }}>
                                {t('GTINDetails.serialNum.serialVal', { serialNo: gs1Array['21'] || '-' })}
                            </CustomText>
                        </Stack>
                    </Stack>
                )}

                {linkSet.length > 0 && (
                    <CustomH2 style={{ paddingLeft: isMobile && '8px' }}>{t('GTINDetails.moreInfo.title')}</CustomH2>
                )}
                {linkSet.map((item, index) => {
                    if (item.name != 'General Information') {
                        let iconSrc;
                        // Set icon source based on item type
                        switch (item.content) {
                            case 'video/mp4':
                                iconSrc = 'icons/Video.svg';
                                break;
                            case 'audio/mp3':
                                iconSrc = 'icons/Audio.svg';
                                break;
                            case 'text/html':
                                iconSrc = 'icons/HTML_Website.svg';
                                break;
                            case 'application/pdf':
                                iconSrc = 'icons/PDF.svg';
                                break;
                            // Add more cases as needed
                            default:
                                iconSrc = 'icons/Others.svg'; // Default icon if type not recognized
                        }

                        return (
                            <Stack
                                direction={'row'}
                                key={index}
                                spacing={{ xs: 1, sm: 1, md: 2 }}
                                justifyContent={'flex-start'}
                                sx={{ borderBottom: '2px dotted #D2DCE6', margin: '8px 0' }}
                            >
                                <img src={iconSrc} width={'32px'} height={'32px'} alt={t('GTINDetails.vectorImg')} />
                                <Stack direction={'column'} spacing={{ xs: 1, sm: 1, md: 1 }} sx={{ marginTop: '8px' }}>
                                    <Link to={`${item.url}`} target="_blank" style={{ textDecoration: 'none' }}>
                                        <CustomText style={{ color: 'red' }}>
                                            {t('GTINDetails.moreInfo.content.title', { productInfo: item.name })}
                                        </CustomText>
                                    </Link>
                                    <CustomText
                                        style={{
                                            paddingTop: '0',
                                            paddingBottom: '8px',
                                            marginTop: '0',
                                        }}
                                    >
                                        {t('GTINDetails.moreInfo.content.context', { context: getCountryFromList(item.context) || '-' })}
                                        &nbsp;/&nbsp;
                                        {t('GTINDetails.moreInfo.content.language', { lang: getLanguageFromList(item.lang) })}
                                    </CustomText>
                                </Stack>
                            </Stack>
                        );
                    } else {
                        return null; // Skip rendering for 'General Information'
                    }
                })}
            </div>
        </>
    );
}
export default SearchDetails;
