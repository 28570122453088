import React from 'react';
import styled from '@emotion/styled';

const IMRContent = styled.div`
  background-color: #e8e6e3;
  padding: 16px 24px;
  p {
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1;
    color: #000000;
    letter-spacing: 0.01071em;
    margin: 0;
  }
`;

const IMRComponent = ({ text }) => {
  return (
    <footer role="contentinfo" aria-label='iMRnumber'>
      <IMRContent>
        <p>{text}</p>
      </IMRContent>
    </footer>
  );
};


export default IMRComponent;
