import React, { useEffect } from "react";
import axios from 'axios';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import Title from '../Title';
import { Link } from 'react-router-dom';
import '../Home.css';
import '../../../fonts.css';
import { useState } from 'react';
import './terms.css';


const StyledBody = styled.p`
  font-family: 'JohnsonText-Regular', sans-serif;
  color: black;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  text-align: justify;
`;



function TermsOfUse() {
  const { t, i18n } = useTranslation();
  const [htmlContent, setHtmlContent] = useState('');
  const linkColor = 'var(--bs-link-color)';
  const applyLinkStyles = (htmlContent) => {
    return htmlContent.replace(
      /<a\s+(?:[^>]*?\s+)?href="([^"]*)"([^>]*)>(.*?)<\/a>/g,
      `<a style="color: ${linkColor}; text-decoration: underline;" href="$1"$2>$3</a>`,
    );
  };
  
  useEffect(() => {
    let language = window.location.href.split('/').pop().split('?')[0];
    localStorage.setItem("tou-language", language);
    i18n.changeLanguage(language);

    const fetchHtmlContent = async () => {
      try {
        const response = await axios.get(`terms/${language}.html`);
        setHtmlContent(applyLinkStyles(response.data));
      } catch (error) {
        console.error('Error fetching HTML content:', error);
      }
    };

    fetchHtmlContent();
  }, []);

  const padding = '16px 0px 16px 0px';
  
  return (
    <div className="legal-container">
      <Stack direction={'row'}>
        <Link to="/terms-of-use">
          <img
            src="images/ep_back.png"
            width={'40px'}
            height={'40px'}
            alt={t("termsOfUse.img")}
            style={{ paddingRight: '8px' }}
          />
        </Link>
        <Title title={t('footer.termsOfUse')} titlePaddingStyle={padding}></Title>
      </Stack>
      <StyledBody>
        <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
      </StyledBody>
    </div>
  );
}
export default TermsOfUse;
