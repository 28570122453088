// usePageTracking.js
import { useEffect } from 'react';
import axios from 'axios';

const usePageTracking = () => {
    const location = window.location;

    useEffect(() => {
        const logPageView = async () => {
            try {
                await axios.post('/save_activity_data/', {

                    activity_type: 'page_view',
                    context: localStorage.getItem('country'),
                    language: localStorage.getItem('language') || localStorage.getItem('i18nextLng'),
                    
                    additional_data: {
                        page: location.hash.indexOf('#/search') === 0 ? 'search' : 'home',
                        query: location.search ? location.search.substring(1) : null,
                        hash: location.hash,
                    },
                    
                });
            } catch (error) {
                console.error('Error logging page view:', error);
            }
        };

        logPageView();
    }, [location]);
};

export default usePageTracking;
