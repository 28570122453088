import React from 'react';
import { Box, Grid } from '@mui/material';
import Frame from './Frame';
import '../../../fonts.css';
import '../Home.css';

function SelectorFrame({ isMobile }) {
  return isMobile ? (
    <Box
      sx={{
        borderRadius: '16px',
        bgcolor: '#E8E6E3', // Existing gray background color
        width: '100%', // Ensure full width
        display: 'flex', // Flex container for alignment
        justifyContent: 'flex-end', // Align contents (dropdowns) to the right
        padding: '16px', // Padding for the component
      }}
    >
      <Frame isMobile={isMobile} />
    </Box>
  ) : (
    <div
      className="selector-frame"
      style={{
        borderRadius: '16px',
        backgroundColor: '#E8E6E3', // Existing gray background color
        width: '100%', // Ensure full width
        display: 'flex', // Flex container for alignment
        justifyContent: 'flex-end', // Align contents (dropdowns) to the right
        padding: '16px', // Padding for the component
      }}
    >
      <Frame />
    </div>
  );
}

export default SelectorFrame;
