import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { MenuItem, Select } from '@mui/material';
import Typography from '@mui/material/Typography';

function CountryList({ onCountryChange }) {
  const [countries, setCountries] = useState([]);
  const userLanguage = navigator.language.split('-')[0];
  const lastLanguage = localStorage.getItem('language') || userLanguage;
  const lastCountry = localStorage.getItem('country');
  const [selectedCountry, setSelectedCountry] = useState(lastCountry);
  useEffect(() => {
    axios
      .get('data/country.json?v=5.6.0')
      .then((response) => {
        setCountries(response.data);
        let filteredCountries = response.data.filter((country) => country.listed);

        // check selected country is in the list
        const country = filteredCountries
          .find((country) => country.iso_3166_1_alpha_2_code === selectedCountry);
        if (!country) {
          // if not, set the default country as selected
          setSelectedCountry(filteredCountries[0]?.iso_3166_1_alpha_2_code);
          localStorage.setItem('country', filteredCountries[0]?.iso_3166_1_alpha_2_code);
        }
      })
      .catch((error) => {
        console.error('Error fetching Data: ', error);
      });
  }, []);

  const getSelectedCountry = () => {
    if (selectedCountry === undefined || selectedCountry === null || countries.length === 0) {
      return '';
    } else {
      return selectedCountry;
    }
  };
  const handleCountryChange = (e) => {
    setSelectedCountry(e.target.value);
    localStorage.setItem('country', e.target.value);
  };

  const getCountryName = (country) => {
    return country[`name_${lastLanguage}`] || country.name_en;
  };
  return (
  <Select id="country-dropdown" value={getSelectedCountry()} onChange={handleCountryChange} className="custom-select-country" aria-labelledby="Country Dropdown">
      {countries
        .filter((country) => country.listed)
        .map((country, index) => (
          <MenuItem key={index} value={country.iso_3166_1_alpha_2_code}>
            {<Typography variant="body2">{getCountryName(country)}</Typography>}
          </MenuItem>
        ))}
    </Select>
  );
}
export default CountryList;
