import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import '../../fonts.css';
import Title from './Title';
import SelectorFrame from './SelectorComponent';
import ScanSearch from './ScanSearchComponent';
import MidComponent from './MidComponent';

const StyledDiv = styled.div`
  font-family: 'JohnsonText-Regular', sans-serif;
  margin-bottom: 10px;
`;

const StyledSelectorFrame = styled(Box)`
  display: flex;
  justify-content: flex-end; /* Align to the right */
  padding: 0 8px; /* Add some horizontal padding */
`;

function Home() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const { t } = useTranslation();

  const padding = '16px 8px 8px 8px';
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      setWindowWidth(window.innerWidth);
    };
    //This line adds an event listener to the resize event of the window
    window.addEventListener('resize', handleResize);
    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      {isMobile ? (
        <>
          <Title title={t('main.title')} isMobile={isMobile} titlePaddingStyle={padding} />
          <Box
            sx={{
              maxWidth: `${windowWidth}`,
              padding: '0px 8px',
            }}
          >
            <StyledDiv>{t('main.patientInfo')}</StyledDiv>
            <StyledSelectorFrame>
              <SelectorFrame isMobile={isMobile} />
            </StyledSelectorFrame>
          </Box>
          <Box
            sx={{
              maxWidth: `${windowWidth}`,
              padding: '0 8px',
              borderBottom: '2px dotted #D2DCE6',
            }}
          >
            <ScanSearch isMobile={isMobile} />
          </Box>
          <Box
            sx={{
              maxWidth: `${windowWidth}`,
              padding: '16px 0',
              borderBottom: '2px dotted #D2DCE6',
              gap: '8px',
            }}
          >
            <MidComponent isMobile={isMobile} />
          </Box>
        </>
      ) : (
        <div className="main-container">
          <Title title={t('main.title')} />
          <StyledDiv>{t('main.patientInfo')}</StyledDiv>
          <StyledSelectorFrame>
            <SelectorFrame />
          </StyledSelectorFrame>
          <ScanSearch />
          <MidComponent />
        </div>
      )}
    </>
  );
}

export default Home;
