import React from 'react';
import { useTranslation } from 'react-i18next';
import CountryList from '../../reusableComponents/CountryList';
import LanguageList from '../../reusableComponents/LanguageList';
import '../../../fonts.css';
import { Grid } from '@mui/material';

function Frame({ isMobile, onCountryChange }) {
  const { t } = useTranslation();

  return isMobile ? (
    <Grid
      container
      direction={'row'}
      justifyContent={'space-between'} // Space between dropdowns
      sx={{
        width: '100%', // Full-width container
        gap: '16px', // Increase space between dropdowns
        padding: '0 0px', // Padding for the component, less space on sides
      }}
    >
      <Grid item sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
        <img src="images/Language.png" width={'30px'} height={'18px'} />
        <LanguageList />
      </Grid>
      <Grid item sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
        <img src="images/Country.png" width={'24.24px'} height={'21.12px'} />
        <CountryList />
      </Grid>
    </Grid>
  ) : (
    <Grid
      container
      direction={'row'}
      justifyContent={'flex-end'} // Align dropdowns to the right
      sx={{
        width: '100%', // Full-width container
        gap: '16px', // Space between items
        padding: '0 16px', // Padding to ensure proper spacing
      }}
    >
      <Grid item sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
        <img src="svg/lang-Vector.svg" width={'30px'} height={'18px'} />
        <LanguageList />
      </Grid>
      <Grid item sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
        <img src="svg/country-Vector.svg" width={'24.24px'} height={'21.12px'} />
        <CountryList />
      </Grid>
    </Grid>
  );
}

export default Frame;
