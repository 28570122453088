export const PRIVACY_POLICY_DATA = [
  {
    country: "GLOBAL",
    region: "Janssen Global",
    languages: [
      {
        language: "ENGLISH",
        code: "en",
        link: "https://www.janssen.com/",
      },
      {
        language: "ESPAÑOL",
        code: "es",
        link: "https://www.janssen.com/es",
      },
      {
        language: "FRANÇAIS",
        code: "fr",
        link: "https://www.janssen.com/fr",
      },
      {
        language: "Português (BR)",
        code: "pt",
        link: "https://www.janssen.com/pt",
      },
      {
        language: "Italiano",
        code: "it",
        link: "https://www.janssen.com/it",
      },
      {
        language: "한국어",
        code: "ko",
        link: "https://www.janssen.com/ko",
      },
      {
        language: "РУССКИЙ",
        code: "ru",
        link: "https://www.janssen.com/ru",
      },
    ],
  },
  {
    country: "Asia Pacific (Regional)",
    region: "Asia Pacific",
    languages: [
      {
        language: "ENGLISH",
        code: "en",
        link: "https://www.janssen.com/apac/",
      },
    ],
    isRegional: true,
  },
  {
    country: "China",
    region: "Asia Pacific",
    languages: [
      {
        language: "中文",
        code: "zh",
        link: "https://www.xian-janssen.com.cn/privacy-policy",
      },
    ],
  },
  {
    country: "India",
    region: "Asia Pacific",
    languages: [
      {
        language: "ENGLISH",
        code: "en",
        link: "https://www.janssen.com/india/privacy-policy",
      },
    ],
  },
  {
    country: "Singapore",
    region: "Asia Pacific",
    languages: [
      {
        language: "ENGLISH",
        code: "en",
        link: "https://www.janssen.com/apac/privacy-policy",
      },
    ],
  },
  {
    country: "South Korea",
    region: "Asia Pacific",
    languages: [
      {
        language: "ENGLISH",
        code: "en",
        link: "https://www.janssen.com/korea/etc/privacy-policy",
      },
    ],
  },
  {
    country: "Taiwan",
    region: "Asia Pacific",
    languages: [
      {
        language: "ENGLISH",
        code: "en",
        link: "https://www.janssen.com/apac/privacy-policy",
      },
    ],
  },
  {
    country: "Thailand",
    region: "Asia Pacific",
    languages: [
      {
        language: "ENGLISH",
        code: "en",
        link: "https://www.janssen.com/apac/privacy-policy",
      },
    ],
  },
  {
    country: "EMEA (Regional)",
    region: "Europe, Middle East, Africa",
    languages: [
      {
        language: "ENGLISH",
        code: "en",
        link: "https://www.janssen.com/emea/",
      },
    ],
    isRegional: true,
  },
  {
    country: "Austria",
    region: "Europe, Middle East, Africa",
    languages: [
      {
        language: "DEUTSCH",
        code: "de",
        link: "https://static.janssen-emea.com/sites/default/files/Austria/Legal%20Docs/PrivacyPolicy.html?tracked&link=CookieInformation_v2.html&uuid=05bb2567-2e4d-45e4-b0fe-5d766e16a492",
      },
    ],
  },
  {
    country: "Belgium",
    region: "Europe, Middle East, Africa",
    languages: [
      {
        language: "ENGLISH",
        code: "en",
        link: "https://static.janssen-emea.com/sites/default/files/Belgium/Legal%20Docs/BE_en%20privacy.html?link=BENE_en_CookieInformationInt_v1.html&uuid=05bb2567-2e4d-45e4-b0fe-5d766e16a492",
      },
      {
        language: "FRANÇAIS",
        code: "fr",
        link: "https://static.janssen-emea.com/sites/default/files/Belgium/Legal%20Docs/BE_fr%20privacy.html?link=BENE_fr%20CookieInformationInt_v1.html&uuid=05bb2567-2e4d-45e4-b0fe-5d766e16a492",
      },
      {
        language: "NEDERLANDS",
        code: "nl",
        link: "https://static.janssen-emea.com/sites/default/files/Belgium/Legal%20Docs/BE_nl%20privacy.html?link=BENE_nl_CookieInformationInt_v1.html&uuid=05bb2567-2e4d-45e4-b0fe-5d766e16a492",
      },
    ],
  },
  {
    country: "Bulgaria",
    region: "Europe, Middle East, Africa",
    languages: [
      {
        language: "БЪЛГАРСКИ",
        code: "bg",
        link: "https://www.janssen.com/bulgaria/privacy-policy",
      },
    ],
  },
  {
    country: "Croatia",
    region: "Europe, Middle East, Africa",
    languages: [
      {
        language: "HRVATSKI",
        code: "hr",
        link: "https://www.janssen.com/croatia/izjava-o-privatnosti",
      },
    ],
  },
  {
    country: "Cyprus",
    region: "Europe, Middle East, Africa",
    languages: [
      {
        language: "ENGLISH",
        code: "en",
        link: "https://www.janssen.com/emea/privacy-policy",
      },
    ],
  },
  {
    country: "Czech Republic",
    region: "Europe, Middle East, Africa",
    languages: [
      {
        language: "ČEŠTINA",
        code: "cs",
        link: "https://www.janssen.com/czech/footer/ochrana-soukromi",
      },
    ],
  },
  {
    country: "Denmark",
    region: "Europe, Middle East, Africa",
    languages: [
      {
        language: "DANSK",
        code: "da",
        link: "https://static.janssen-emea.com/sites/default/files/Finland/uploads/privacy/DK_Privacy-NEW-Sept2020.html?tracked=&link=DK_CookieInformationInt_vTwo.html&uuid=05bb2567-2e4d-45e4-b0fe-5d766e16a492",
      },
    ],
  },
  {
    country: "Estonia",
    region: "Europe, Middle East, Africa",
    languages: [
      {
        language: "EESTI",
        code: "et",
        link: "https://www.janssen.com/estonia/privacy-policy",
      },
    ],
  },
  {
    country: "Finland",
    region: "Europe, Middle East, Africa",
    languages: [
      {
        language: "SUOMI",
        code: "fi",
        link: "https://static.janssen-emea.com/sites/default/files/Finland/uploads/privacy/FI_Policy-NEW-Sept2020.html?tracked=&link=FI_CookieInformationInt_vTwo.html&uuid=05bb2567-2e4d-45e4-b0fe-5d766e16a492",
      },
    ],
  },
  {
    country: "France",
    region: "Europe, Middle East, Africa",
    languages: [
      {
        language: "FRANÇAIS",
        code: "fr",
        link: "https://static.janssen-emea.com/sites/default/files/France/uploads/Mentions%20l%C3%A9gales/Janssen/Privacy_FR.HTML?link=CookieInformationInt_v1.html&uuid=05bb2567-2e4d-45e4-b0fe-5d766e16a492",
      },
    ],
  },
  {
    country: "Germany",
    region: "Europe, Middle East, Africa",
    languages: [
      {
        language: "DEUTSCH",
        code: "de",
        link: "https://static.janssen-emea.com/sites/default/files/Germany/uploads/Legal/PrivacyPolicy.html?link=CookieInformationInt_v1.html&uuid=05bb2567-2e4d-45e4-b0fe-5d766e16a492",
      },
    ],
  },
  {
    country: "Greece",
    region: "Europe, Middle East, Africa",
    languages: [
      {
        language: "ΕΛΛΗΝΙΚΑ",
        code: "el",
        link: "https://www.janssen.com/greece/privacy_policy",
      },
    ],
  },
  {
    country: "Hungary",
    region: "Europe, Middle East, Africa",
    languages: [
      {
        language: "MAGYAR",
        code: "hu",
        link: "https://www.janssen.com/hungary/privacy_policy",
      },
    ],
  },
  {
    country: "Ireland",
    region: "Europe, Middle East, Africa",
    languages: [
      {
        language: "ENGLISH",
        code: "en",
        link: "https://www.janssen.com/ireland/privacy-policy",
      },
    ],
  },
  {
    country: "Italy",
    region: "Europe, Middle East, Africa",
    languages: [
      {
        language: "ITALIANO",
        code: "it",
        link: "https://www.janssen.com/italy/privacy-policy",
      },
    ],
  },
  {
    country: "Latvia",
    region: "Europe, Middle East, Africa",
    languages: [
      {
        language: "LATVIEŠU",
        code: "lv",
        link: "https://www.janssen.com/latvia/privacy-policy",
      },
    ],
  },
  {
    country: "Lithuania",
    region: "Europe, Middle East, Africa",
    languages: [
      {
        language: "LIETUVIŲ",
        code: "lt",
        link: "https://www.janssen.com/lithuania/privatumo-politika",
      },
    ],
  },
  {
    country: "Luxembourg",
    region: "Europe, Middle East, Africa",
    languages: [
      {
        language: "ENGLISH",
        code: "en",
        link: "https://www.janssen.com/emea/privacy-policy",
      },
    ],
  },
  {
    country: "Malta",
    region: "Europe, Middle East, Africa",
    languages: [
      {
        language: "ENGLISH",
        code: "en",
        link: "https://www.janssen.com/emea/privacy-policy",
      },
    ],
  },
  {
    country: "Netherlands",
    region: "Europe, Middle East, Africa",
    languages: [
      {
        language: "NEDERLANDS",
        code: "nl",
        link: "https://static.janssen-emea.com/sites/default/files/Belgium/Legal%20Docs/NL_nl%20privacy.html?link=BENE_nl_CookieInformationInt_v1.html&uuid=05bb2567-2e4d-45e4-b0fe-5d766e16a492",
      },
    ],
  },
  {
    country: "Norway",
    region: "Europe, Middle East, Africa",
    languages: [
      {
        language: "NORSK",
        code: "no",
        link: "https://static.janssen-emea.com/sites/default/files/Finland/uploads/privacy/NO_Policy-NEW-Sept2020.html?tracked=&link=NO_CookieInformationInt_vTwo.html&uuid=05bb2567-2e4d-45e4-b0fe-5d766e16a492",
      },
    ],
  },
  {
    country: "Poland",
    region: "Europe, Middle East, Africa",
    languages: [
      {
        language: "POLSKI",
        code: "pl",
        link: "https://www.janssen.com/poland/polityka-prywatnosci",
      },
    ],
  },
  {
    country: "Portugal",
    region: "Europe, Middle East, Africa",
    languages: [
      {
        language: "PORTUGUÊS",
        code: "pt",
        link: "https://www.janssen.com/portugal/politica-de-privacidade",
      },
    ],
  },
  {
    country: "Romania",
    region: "Europe, Middle East, Africa",
    languages: [
      {
        language: "ROMÂNĂ",
        code: "ro",
        link: "https://www.janssen.com/romania/politica-de-confidentialitate",
      },
    ],
  },
  {
    country: "Slovakia",
    region: "Europe, Middle East, Africa",
    languages: [
      {
        language: "SLOVENČINA",
        code: "sk",
        link: "https://www.janssen.com/slovakia/footer/ochrana-sukromia",
      },
    ],
  },
  {
    country: "Slovenia",
    region: "Europe, Middle East, Africa",
    languages: [
      {
        language: "SLOVENŠČINA",
        code: "sl",
        link: "https://www.janssen.com/slovenia/zasebnost",
      },
    ],
  },
  {
    country: "Spain",
    region: "Europe, Middle East, Africa",
    languages: [
      {
        language: "ESPAÑOL",
        code: "es",
        link: "https://www.janssen.com/spain/politica-privacidad",
      },
    ],
  },
  {
    country: "Sweden",
    region: "Europe, Middle East, Africa",
    languages: [
      {
        language: "SVENSKA",
        code: "sv",
        link: "https://static.janssen-emea.com/sites/default/files/Finland/uploads/privacy/SE_Policy-NEW-Sept2020.html?tracked=&link=SE_CookieInformationInt_vTwo.html&uuid=05bb2567-2e4d-45e4-b0fe-5d766e16a492",
      },
    ],
  },
  {
    country: "Turkey",
    region: "Europe, Middle East, Africa",
    languages: [
      {
        language: "TÜRKÇE",
        code: "tr",
        link: "https://www.janssen.com/turkey/gizlilik",
      },
    ],
  },
  {
    country: "United Kingdom",
    region: "Europe, Middle East, Africa",
    languages: [
      {
        language: "ENGLISH",
        code: "en",
        link: "https://www.janssen.com/uk/privacy-policy",
      },
    ],
  },
  {
    country: "United States of America",
    region: "North America",
    languages: [
      {
        language: "ENGLISH",
        code: "en",
        link: "https://www.janssen.com/us/privacy-policy",
      },
    ],
  },
];



export const TERMS_OF_USE_DATA = [
  {
    country: "China",
    region: "Asia Pacific",
    languages: [
      {
        language: "中文",
        code: "zh",
      },
    ],
  },
  {
    country: "South Korea",
    region: "Asia Pacific",
    languages: [
      {
        language: "한국어",
        code: "ko",
      },
    ],
  },
  {
    country: "Thailand",
    region: "Asia Pacific",
    languages: [
      {
        language: "ไทย",
        code: "th",
      },
    ],
  },
  {
    country: "Austria",
    region: "Europe, Middle East, Africa",
    languages: [
      {
        language: "DEUTSCH",
        code: "de-at",
      },
    ],
  },
  {
    country: "Belgium",
    region: "Europe, Middle East, Africa",
    languages: [
      {
        language: "FRANÇAIS",
        code: "fr-be",
        IMRContent: 'EM-172051 - 11/2024'
      },
      {
        language: "DEUTSCH",
        code: "de-lu",
        IMRContent: ' EM-172051 - 11/2024'
      },
      {
        language: "NEDERLANDS",
        code: "nl-be",
        IMRContent: 'EM-172051 - 11/2024'
      },
    ],
  },
  {
    country: "Bulgaria",
    region: "Europe, Middle East, Africa",
    languages: [
      {
        language: "БЪЛГАРСКИ",
        code: "bg",
      },
    ],
  },
  {
    country: "Croatia",
    region: "Europe, Middle East, Africa",
    languages: [
      {
        language: "HRVATSKI",
        code: "hr",
        IMRContent: 'CP-483576 - 10/2024'
      },
    ],
  },
  {
    country: "Cyprus",
    region: "Europe, Middle East, Africa",
    languages: [
      {
        language: "ΕΛΛΗΝΙΚΑ",
        code: "el-cy",
        IMRContent: 'CP-484353 - 10/2024'
      },
    ],
  },
  {
    country: "Czech Republic",
    region: "Europe, Middle East, Africa",
    languages: [
      {
        language: "ČEŠTINA",
        code: "cs",
        IMRContent: 'CP-467772 - 07/2024'
      },
    ],
  },
  {
    country: "Denmark",
    region: "Europe, Middle East, Africa",
    languages: [
      {
        language: "DANSK",
        code: "da",
        IMRContent: 'EM-165152 - 09/2024'
      },
    ],
  },
  {
    country: "Estonia",
    region: "Europe, Middle East, Africa",
    languages: [
      {
        language: "EESTI",
        code: "et",
        IMRContent: 'EM-170939 - 11/2024'
      },
    ],
  },
  {
    country: "Finland",
    region: "Europe, Middle East, Africa",
    languages: [
      {
        language: "SUOMI",
        code: "fi",
      },
      {
        language: "SVENSKA",
        code: "sv-fi",
      },
    ],
  },
  {
    country: "France",
    region: "Europe, Middle East, Africa",
    languages: [
      {
        language: "FRANÇAIS",
        code: "fr",
        IMRContent: 'CP-491434 - 11/2024'
      },
    ],
  },
  {
    country: "Germany",
    region: "Europe, Middle East, Africa",
    languages: [
      {
        language: "DEUTSCH",
        code: "de",
        IMRContent: 'EM-169108 - 10/2024'
      },
    ],
  },
  {
    country: "Greece",
    region: "Europe, Middle East, Africa",
    languages: [
      {
        language: "ΕΛΛΗΝΙΚΑ",
        code: "el",
        IMRContent: 'CP-490860'
      },
    ],
  },
  {
    country: "Hungary",
    region: "Europe, Middle East, Africa",
    languages: [
      {
        language: "MAGYAR",
        code: "hu",
        IMRContent: 'CP-469784 - 08/2024'
      },
    ],
  },
  {
    country: "Iceland",
    region: "Europe, Middle East, Africa",
    languages: [
      {
        language: "Íslenska",
        code: "is",
        IMRContent: 'CP-477796 - 09/2024'
      },
    ],
  },
  {
    country: "Ireland",
    region: "Europe, Middle East, Africa",
    languages: [
      {
        language: "ENGLISH",
        code: "en-ie",
      },
    ],
  },
  {
    country: "Italy",
    region: "Europe, Middle East, Africa",
    languages: [
      {
        language: "ITALIANO",
        code: "it",
        IMRContent: 'CP-476718 - 09/2024'
      },
    ],
  },
  {
    country: "Latvia",
    region: "Europe, Middle East, Africa",
    languages: [
      {
        language: "LATVIEŠU",
        code: "lv",
        IMRContent: 'EM-170611 - 11/2024'
      },
    ],
  },
  {
    country: "Lithuania",
    region: "Europe, Middle East, Africa",
    languages: [
      {
        language: "LIETUVIŲ",
        code: "lt",
        IMRContent: 'EM-170390 - 11/2024'
      },
    ],
  },
  {
    country: "Luxembourg",
    region: "Europe, Middle East, Africa",
    languages: [
      {
        language: "FRANÇAIS",
        code: "fr-lu",
        IMRContent: 'EM-172051 - 11/2024'
      },
      {
        language: "DEUTSCH",
        code: "de-lu",
        IMRContent: 'EM-172051 - 11/2024'
      },
    ],
  },
  {
    country: "Malta",
    region: "Europe, Middle East, Africa",
    languages: [
      {
        language: "ENGLISH",
        code: "en",
        IMRContent: 'CP-484421 - 10/2024'
      },
    ],
  },
  {
    country: "Netherlands",
    region: "Europe, Middle East, Africa",
    languages: [
      {
        language: "NEDERLANDS",
        code: "nl",
        IMRContent: 'EM-172054 - 11/2024'
      },
    ],
  },
  {
    country: "Norway",
    region: "Europe, Middle East, Africa",
    languages: [
      {
        language: "NORSK",
        code: "no",
        IMRContent: 'CP-491478 - 12/2024'
      },
    ],
  },
  {
    country: "Poland",
    region: "Europe, Middle East, Africa",
    languages: [
      {
        language: "POLSKI",
        code: "pl",
        IMRContent: 'CP-470559 - 09/2024'
      },
    ],
  },
  {
    country: "Portugal",
    region: "Europe, Middle East, Africa",
    languages: [
      {
        language: "PORTUGUÊS",
        code: "pt",
      },
    ],
  },
  {
    country: "Romania",
    region: "Europe, Middle East, Africa",
    languages: [
      {
        language: "ROMÂNĂ",
        code: "ro",
        IMRContent: 'CP-476379 - 09/2024'
      },
    ],
  },
  {
    country: "Slovakia",
    region: "Europe, Middle East, Africa",
    languages: [
      {
        language: "SLOVENČINA",
        code: "sk",
        IMRContent: 'CP-470910 - 11/2024'
      },
    ],
  },
  {
    country: "Slovenia",
    region: "Europe, Middle East, Africa",
    languages: [
      {
        language: "SLOVENŠČINA",
        code: "sl",
        IMRContent: 'EM-169688 - 11/2024'
      },
    ],
  },
  {
    country: "Spain",
    region: "Europe, Middle East, Africa",
    languages: [
      {
        language: "ESPAÑOL",
        code: "es",
        IMRContent: 'CP-476389 - 09/2024'
      },
    ],
  },
  {
    country: "Sweden",
    region: "Europe, Middle East, Africa",
    languages: [
      {
        language: "SVENSKA",
        code: "sv",
        IMRContent: 'CP-474269 - 09/2024'
      },
    ],
  },
  {
    country: "Turkey",
    region: "Europe, Middle East, Africa",
    languages: [
      {
        language: "TÜRKÇE",
        code: "tr",
      },
    ],
  },
  {
    country: "United States of America",
    region: "North America",
    languages: [
      {
        language: "ENGLISH",
        code: "en-us",
      },
    ],
  },
];