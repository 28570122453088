
import axios from 'axios';

export const clickTrack = (eventName, data) => {
    document.dispatchEvent(new CustomEvent('clickTrack', { detail: {
        ...data,
        event_name: eventName
    }}));
};

document.addEventListener('clickTrack', async (event) => {
    try {
        const { event_name, ...additionalData } = event.detail;

        const response = await axios.post('/save_activity_data/', {

                    activity_type: 'click_track',
                    context: localStorage.getItem('country'),
                    language: localStorage.getItem('language') || localStorage.getItem('i18nextLng'),
                    event_name,

                    additional_data: {
                        page: window.location.hash.replace(/#\/([^/]*).*/, '$1') || 'home',
                        hash: window.location.hash,
                        ...additionalData
                    }

                }, {
                    headers: { 'Content-Type': 'application/json' }
                });

        console.log('Activity data saved successfully:', response.data);
    } catch (error) {
        console.error('Failed to save activity data:', error);
    }
});
